input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.2rem;
  height: 1.2rem;
  border: 0.15rem solid currentColor;
  border-radius: 50%;
  transform: translateY(0.15rem);
  display: grid;
  place-content: center;
  cursor: pointer;
}

input.survey-question-no[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  border-radius: 5%;
  background-color: transparent;
  border: 3px solid #404040;
  width: 6.5rem;
  height: 3.1rem;
  outline-width: 0;
  color: white;
  font-size: 3.2rem;
  cursor: pointer;
}

input.survey-question-yes[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  border-radius: 5%;
  background-color: transparent;
  border: 3px solid #404040;
  width: 6.5rem;
  height: 3.1rem;
  outline-width: 0;
  color: white;
  font-size: 3.2rem;
  cursor: pointer;
}

input.survey-question-no[type="radio"]::before {
  content: "";
  width: 6.3rem;
  height: 2.9rem;
  border-radius: 5%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: 0 0 0.5px 0.5px inset #404040;
  background-color: #82dc36;
}

input.survey-question-yes[type="radio"]::before {
  content: "";
  width: 6.3rem;
  height: 2.9rem;
  border-radius: 5%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: 0 0 0.5px 0.5px inset #404040;
  background-color: #82dc36;
}

input[type="radio"]::before {
  content: "";
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1rem 1rem var(--form-control-color);
  background-color: CanvasText;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

.survey-modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #000;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 1000;
  overflow-y: scroll;
}

.survey-modal-close {
  display:none;
  pointer-events: none;
}

.survey-modal-success {
  display: block;
}

.survey-modal-success-none {
  display: none;
}

.survey-modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.survey-modal-content {
  width: 600px;
  background-color: #fff;
  border-radius: 12px;
}

.survey-modal-header {
  padding: 0px 30px 15px 30px;
  font-size: 1.75rem;
  text-align: left;
  font-weight: bold;
}

.survey-modal-title {
  padding-bottom: 10px;
}

.survey-modal-h2 {
  padding-bottom: 0.8rem;
}

.survey-modal-body {
  padding: 5px 30px 20px 30px;
  text-align: left;
}

.survey-modal-subheader {
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: bold;
  letter-spacing: 0.5pt;
}

.survey-modal-question-flex {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 0.7rem;
}

.survey-modal-copy-rank {
  font-size: 1.3rem;
  line-height: 1.7rem;
  padding-bottom: 0.4rem;
}

.survey-modal-copy-body {
  font-size: 1.3rem;
  line-height: 1.7rem;
  padding-bottom: 0.4rem;

  width: 55%;
}

.survey-modal-indent {
  margin-left: 1rem;
}

/* .survey-options {
      margin-bottom: 1rem;
      margin-left: 1rem;
  } */

.survey-options {
  margin-bottom: 1rem;
  margin-right: 1.5rem;
  display: flex;
  justify-self: flex-end;
  justify-content: space-around;
  width: 45%;
}

.survey-options-extra-margin {
  margin-bottom: 1.6rem;
}

.survey-rank-radio {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.survey-options-wide {
  display: block;
}

.survey-options-responsive {
  display: none;
}

.survey-label-radio-rank {
  font-size: 1.25rem;
  margin-right: 0.5rem;
  margin-top: 0.3rem;
  display: grid;
  gap: 0.2em;
}

.survey-rank-text {
  text-align: center;
  cursor: pointer;
}

.survey-label-text {
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
  margin-left: 1rem;
  z-index: 99;
}

.survey-label-radio {
  font-size: 1.25rem;
  margin-right: 0.5rem;
  margin-top: 0.7rem;
  display: grid;
  grid-template-columns: 1rem auto;
  gap: 0.2em;
}

.survey-radio {
  margin-right: 0.25rem;
}

ol li.survey-modal-li {
  margin-top: 12px;
  margin-bottom: 12px;
}

.survey-modal-footer {
  padding: 0px 8px 20px 8px;
}

.close-survey-modal {
  position: relative;
  left: 97%;
  top: 8px;
  width: 30px;
  cursor: pointer;
}

.background-green-survey-modal {
  background-color: #82dc36;
  margin-bottom: 12px;
  line-height: 5px;
  width: 0px;
  margin-left: 30px;
  animation-name: stretch;
  animation-duration: 800ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 600ms;
}

.survey-input-pass {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1rem;
}

.survey-pass-error {
  color: red;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.survey-modal-error {
  color: red;
  margin-top: 1rem;
}

@keyframes stretch {
  from {
    width: 0px;
  }
  to {
    width: 100px;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  /* .survey-modal-content {
      width: 90%;
      margin-right: auto;
      margin-left: auto;
    } */

  /* .survey-modal-footer {
      padding: 0px 30px 20px 30px;
    } */
}

@media screen and (max-width: 680px) {
  /* .survey-options {
        margin-bottom: 1rem;
        margin-left: .25rem;
    }

    .survey-options-extra-margin {
        margin-bottom: 1.6rem;
    } */
}

@media screen and (max-width: 570px) {
  .survey-modal-copy-body {
    width: 45%;
  }

  .survey-options {
    width: 55%;
  }
}

@media screen and (max-width: 475px) {
  .survey-modal-copy-body {
    width: 45%;
  }

  .survey-options {
    width: 55%;
    margin-right: 1rem;
  }

  .survey-label-text {
    margin-left: .5rem;
  }

  input.survey-question-no[type="radio"] {
    width: 5.5rem;
  }

  input.survey-question-yes[type="radio"] {
    width: 5.5rem;
  }

  input.survey-question-no[type="radio"]::before {
    width: 5.3rem;
  }

  input.survey-question-yes[type="radio"]::before {
    width: 5.3rem;
  }
}

@media screen and (max-width: 420px) {
  .survey-options {
    margin-right: .5rem;
  }

  .survey-label-text {
    margin-left: .3rem;
  }

  input.survey-question-no[type="radio"] {
    width: 4.72rem;
  }

  input.survey-question-yes[type="radio"] {
    width: 4.72rem;
  }

  input.survey-question-no[type="radio"]::before {
    width: 4.52rem;
  }

  input.survey-question-yes[type="radio"]::before {
    width: 4.52rem;
  }
}

@media screen and (max-height: 600px) {
  .survey-modal {    
    position: absolute;
    overflow-y: scroll;
    height: initial;
    top: 0;
    bottom: initial;
  }
}
