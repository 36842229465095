.video_modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #000;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 1000;
}

.video_style_lightbox {
  width: 100%;
}

.video_modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.video_modal-content {
  width: 60%;
}

.video_modal-header {
  padding: 30px 30px 20px 30px;
  font-size: 28px;
  text-align: left;
  margin-bottom: -40px;
}

.video_modal-body {
  padding: 10px 30px;
  text-align: left;
}

.video_modal-footer {
  padding: 10px 0 20px 0;
}

.video_close-modal {
  position: relative;
  right: -100%;
  width: 30px;
  cursor: pointer;
}

/* React Player Responsive Sizing */

.react-player {
  height: auto !important;
  aspect-ratio: 16/9;
}

@media screen and (max-width: 768px) {
  .react-player {    
    width: 90vw !important;
  }

  .video_modal-content {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  .video_close-modal {
    display: none;
  }
}
