.share-hero-bg {
    background-color: #292e33;
    background-image: url(../../public/images/background-circles_1px_33opacity.png);
    background-repeat: no-repeat;
    background-position-x: 75%;
    background-position-y: 70px;
    background-size: 700px;
}

.share-container {
    max-width: 1280px;
    margin-left: auto;
    margin-right: auto;
}

.share-hero {
    margin-top: 70px;
    color: #fff;
    margin-left: 30px;
    margin-right: 30px;
}

h1.share-title {
    font-size: 4.5rem;
    line-height: 110%;
    font-weight: 600;
    padding-top: 80px;
    margin-bottom: 30px;
}

h2.share-subtitle {
    margin-top: 30px;
    padding-bottom: 60px;
    font-size: 1.5rem;
    font-weight: 200;
    line-height: 32px;
}

a.share-web {
    font-size: .9rem;
    font-weight: 100;
    color: #4D5257;
    line-height: 1.5rem;
    text-decoration: none;
}

a.share-web:hover {
    color: #B3B7BC;
}

button.share-btn {
    background-color: #1A1D21;
    font-size: .8rem;
    padding: 7px 12px;
    margin: 5px 0;
    border-radius: 8px;
    color: #fff;
    border: none;
}

@media screen and (max-width: 768px) {
    .share-hero-bg {
        background-position-x: 0%;
    }

    .share-hero {
        margin-left: 5%;
        margin-right: 5%;
    }

    h1.share-title {
        padding-top: 60px;
        font-size: 3.5rem;
    }

    h2.share-subtitle {
        padding-bottom: 32px;
    }
}

@media screen and (max-width: 510px) {
    h1.share-title {
        font-size: 2.4rem;
    }
}

@media screen and (max-width: 360px) {
    h1.share-title {
        font-size: 2.3rem;
    }
}