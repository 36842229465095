.compete-hero-container {
    max-width: 1280px;
    margin: auto;
    padding: 50px 0 0 0;
}

h1.compete-hero-header {
    font-size: 4.5rem;
    font-weight: 700;
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
}

@media screen and (max-width: 768px) {
    h1.compete-hero-header {
        font-size: 3.5rem;
    }
}

@media screen and (max-width: 400px) {
    h1.compete-hero-header {
        font-size: 2.5rem;
    }
}