.gamecard-bg {
    background-color: #EAEDEF;
}

.gamecard-container {
    max-width: 1280px;
    margin: auto;
    padding: 50px 0 50px 0;
}

h1.gamecard-header {
    font-size: 4.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 32px;
}

.gamecard-row {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
}

.gamecard-col {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
}

.gamecard-img {
    border: 0;
    border-radius: 12px;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 32px;
}

.gamecard-caption {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 24px;
}

.gamecard-title {
    font-size: 42px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 16px;
}

.gamecard-subtitle {
    font-size: 24px;
    font-weight: 400;    
    margin-top: 20px;
    margin-bottom: 24px;
}

.gamecard-right-arrow {
    display: inline-flex;
    padding-left: 5px;
    width: 12%;
}

@media screen and (max-width: 768px) {
    .gamecard-col {
        max-width: 100%;
        flex-basis: 100%;
    }

    h1.gamecard-header {
        font-size: 3.5rem;
    }

    .gamecard-img {
        margin-top: 30px;
    }
}

@media screen and (max-width: 400px) {
    h1.gamecard-header {
        font-size: 2.5rem;
    }

    .gamecard-title {
        font-size: 2rem;
    }

    .gamecard-col {
        padding-right: 0px;
        padding-left: 0px;
    }
}