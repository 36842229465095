.share-modal {
  display: none;
}

.share-modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.share-modal-dl.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.share-modal-dl {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #000;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 1000;
}

.share-modal-content {
  width: 500px;
  background-color: #fff;
  border-radius: 12px;
}

.share-modal-header {
  padding: 30px 30px 20px 30px;
  font-size: 28px;
  text-align: left;
  margin-bottom: -40px;
}

.share-modal-h2 {
  padding-bottom: 0.8rem;
  padding-left: 20px;
}

.share-modal-body {
  padding: 5px 30px;
  text-align: left;
}

.share-modal-copy-body {
  font-size: 1rem;
  padding-bottom: 1rem;
}

.share-modal-indent {
  margin-left: 1rem;
}

ol li.share-modal-li {
  margin-top: 12px;
  margin-bottom: 12px;
}

.share-modal-footer {
  padding: 0px 8px 20px 8px;
}

.close-share-modal {
  position: relative;
  left: 97%;
  top: -50px;
  width: 30px;
}

.background-green-share-modal {
  background-color: #82dc36;
  margin-bottom: 12px;
  line-height: 5px;
  width: 0px;
  margin-left: 30px;
  animation-name: stretch;
  animation-duration: 800ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 600ms;
}

.background-green-share-modal-dl {
    background-color: #82dc36;
    margin-bottom: 12px;
    line-height: 5px;
    width: 0px;
    margin-left: 30px;
    animation-name: stretch-more;
    animation-duration: 5000ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-delay: 600ms;
  }

@keyframes stretch {
  from {
    width: 0px;
  }
  to {
    width: 100px;
  }
}

@keyframes stretch-more {
    from {
      width: 0px;
    }
    to {
      width: 150px;
    }
  }

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .share-modal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: #000;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    z-index: 1000;
  }

  .share-modal-h2 {
    padding-left: 0;
  }

  .share-modal-content {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  .share-modal-footer {
    padding: 0px 30px 20px 30px;
  }
}
