.image_modal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: #000;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
    z-index: 1000;
  }
  
  .image_style_lightbox {
    width: 100%;
  }
  
  .image_modal.enter-done {
    opacity: 1;
    pointer-events: visible;
  }
  
  .image_modal-content {
    width: 65%;
    margin-left: 15%;
    margin-right: 15%;
  }

  .image_modal-snapshot {
    width: 100%;
  }
  
  .image_modal-header {
    padding: 30px 30px 20px 30px;
    font-size: 28px;
    text-align: left;
    margin-bottom: -40px;
  }
  
  .image_modal-body {
    padding: 10px 30px;
    text-align: left;
  }
  
  .image_modal-footer {
    padding: 10px 0 20px 0;
  }
  
  .image_close-modal {
    position: relative;
    right: -100%;
    margin-bottom: -10px;
    width: 30px;
    cursor: pointer;
  }
  
  /* React Player Responsive Sizing */
  
  .react-player {
    height: auto !important;
    aspect-ratio: 16/9;
  }
  
  @media screen and (max-width: 768px) {
    .react-player {    
      width: 90vw !important;
    }
  
    .image_modal-content {
      width: 100%;
      margin-right: auto;
      margin-left: auto;
    }
  
    .image_close-modal {
      display: none;
    }
  }
  