.active-comp-bg {
    background-color: #EAEDEF;
}

.active-comp-container {
    max-width: 1280px;
    margin: auto;
    padding: 50px 0 50px 0;
}

h1.active-comp-header {
    font-size: 4.5rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 32px;
}

.active-comp-row {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
}

.active-comp-col {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;
}

.active-comp-img {
    border: 0;
    border-radius: 12px;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    margin-bottom: 16px;
}

.active-comp-caption {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 24px;
}

.active-comp-title {
    font-size: 42px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 16px;
}

.active-comp-subtitle {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 24px;
}

.active-comp-right-arrow {
    display: inline-flex;
    padding-left: 5px;
    width: 12%;
}

@media screen and (max-width: 768px) {
    .active-comp-col {
        max-width: 100%;
        flex-basis: 100%;
    }

    h1.active-comp-header {
        font-size: 3.5rem;
    }

    .active-comp-img {
        margin-top: 30px;
    }
}

@media screen and (max-width: 413px) {
    h1.active-comp-header {
        font-size: 2.5rem;
    }

    .active-comp-title {
        font-size: 2rem;
    }
}