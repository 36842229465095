.footerBackground {
	background-color: #4d5257;
	border-top-left-radius: 40px;
	border-top-right-radius: 40px;
}

.footerContainer {
	padding-top: 80px;
	padding-left: max(7vw, 40px);
	padding-right: max(7vw, 40px);
	margin: auto;
	max-width: 1920px;

	@media only screen and (max-width: 767px) {
		padding-top: 32px;
		padding-left: 32px;
		padding-right: 32px;
	}
}

.secondaryFooterContainer {
	padding-top: 40px;
	padding-left: max(7vw, 40px);
	padding-right: max(7vw, 40px);
	padding-bottom: 40px;
	margin: auto;
	max-width: 1920px;

	@media only screen and (max-width: 1919px) {
		padding-top: 24px;
	}

	@media only screen and (max-width: 767px) {
		padding: 44px 32px 40px 32px;
	}
}

.secondaryFooterColumn {
	display: flex;
	flex-direction: row;
	gap: 24px;
	justify-content: space-between;

	@media only screen and (max-width: 1023px) {
		flex-direction: column-reverse;
		gap: 2px;
	}
}

.secondaryRightWrap {
	display: flex;
	flex-direction: row;
	gap: 20px;

	@media only screen and (max-width: 1919px) {
		gap: 2.088vw;
	}

	@media only screen and (max-width: 767px) {
		white-space: nowrap;
		flex-wrap: wrap;
		gap: 0 20px;
	}
}

.footerColumn {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.footerPageLinksCol {
	padding-top: 10px;
}

.footerPageLinksHeader {
	color: #fff;
	opacity: 0.66;
	font-weight: 700;
	font-size: 32px;

	@media only screen and (max-width: 1919px) {
		font-size: 1.6704vw;
		margin-bottom: 2.088vw;
	}

	@media only screen and (max-width: 767px) {
		font-size: 20px;
		margin-bottom: 32px;
	}
}

.footerPageLinks {
	color: #fff;
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 15px;
	margin-top: 10px;

	@media only screen and (max-width: 1919px) {
		font-size: max(1.044vw, 12px);
		margin-bottom: 1.6704vw;
	}

	@media only screen and (max-width: 767px) {
		font-size: 16px;
		margin-bottom: 24px;
	}
}

.footerLinkWhite {
	text-decoration: none;
	color: #fff;
}

.footerLinkWhite:hover {
	text-decoration: underline;
	color: #fff;
}

.footerLinkGray {
	text-decoration: none;
	color: #b3b7bc;

	@media only screen and (max-width: 767px) {
		color: #fff;
	}
}

.footerLinkGray:hover {
	text-decoration: underline;
	color: #b3b7bc;

	@media only screen and (max-width: 767px) {
		color: #fff;
	}
}

.newsletterBox {
	border-radius: 32px;
	background: white;
	overflow: hidden;
	z-index: 5;
	position: relative;
	color: #4d5257;
	padding: 40px;

	@media only screen and (max-width: 1919px) {
		padding: 2.088vw;
	}

	@media only screen and (max-width: 1023px) {
		margin-bottom: 54px;
	}

	@media only screen and (max-width: 767px) {
		padding: 24px;
		border-radius: 24px;
	}

	a {
		display: inline-block;
		font-weight: 700;
		color: #4d5257;
	}

	a:hover {
		text-decoration: underline;
		color: #4d5257;
	}

	.newsletterLegalText {
		font-size: 12px;
	}

	//overwrite newsletter-service
	.go3524453814.go3524453814.go3524453814:hover {
		border-color: #292e33 !important;
	}

	input {
		width: 100% !important;
		border: none !important;
		border-bottom: 1px solid #292e33 !important;
		border-radius: 0 !important;
		appearance: none !important;
		margin: 0 !important;
		padding: 0 !important;
		line-height: 64px !important;
		color: #000 !important;
		outline: 0 !important;
		font-size: 24px !important;
		font-style: normal !important;
		font-weight: 500 !important;
		line-height: 150% !important;
		height: auto !important;
		font-family: 'Roobert', sans-serif !important;

		@media only screen and (max-width: 767px) {
			font-size: 16px !important;
		}

		&:hover {
			border-bottom: 1px solid #292e33 !important;
			border-color: #292e33 !important;
		}

		@media only screen and (max-width: 767px) {
			line-height: 40px !important;
		}
	}

	button {
		min-width: 3.3408vw !important;
		width: 3.3408vw !important;
		height: 3.3408vw !important;
		padding: 0 !important;
		border-radius: 24px !important;
		background-color: #82dc36 !important;
		background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAM4SURBVHgB7Zq7bhpBFIYPF9tpLBFfpJTb2EZyg7uU0LmMu3SxnyD2E8Q8gZMylaFMRdKFyvgJoEHiUmQfANCmC/f8R4YGz8AadnYGM5+0AnxWFvPvmfPPmYHIYrFYLBaLZUOJkGJOT09Tw+EwIQi5tVrNJc0oF+Dk5OTPeDx2BCEP13Wj0ciTRqKkmG63e4aXiiDEWZE7Pj7+QhqJkWI8z/vXbre/7+3tvY1EIu8Ft6T39/cJ9zySBpQLMKXT6fzGQHnKpQXh9MHBQWp3d7fIglGIKK8Bs6AmXKMm3EnCbjQazYRZHEMXgGFn6Pf7D/RUB2YJVQQtAjDJZNJBJjzIHGJraytTrVYrpBjlLiCDnzCKYgaXKwgnkCFlni6kmNCKoIhWq+Wh8OXj8XgSH5OCW85VO4RWAZiJTf5Y4BAJ3FMkBWirASKwKLrFi2xhVOn1ehnXdT0KEKMEYFAcL0ejEdtkKA5hnAAM2+RgMChIHCJQEbS5wDzY/uY4hIMMKWO6fKAA0F4EZbBDHB4e/kIWpPHx3Uz4Da6PQTiEsQIwLILqRspoAaYsaqQQc7CeeFymkVoLARg85RLWA3/x9lwQTsVisXNMmSJnDb0AI11gHrDJNIpggQKySV8CcMXFP06QIUAAh+QLJheN1IXfRsqvAGNaPy797DcauQ4IiK9+bnrNAvgqhq9WACygsn7ui5M/LtaoCL5oN2njbXCtpgC2yD5j8LLN1MoyXeLaCMAnSJjXssqe582SZVrktZgCGPw9Xi4l4Sz8/paWxGgBHMdJbG9vc8qnRHF0iDf1et2X38swVoBF5wa4rvDkf9KKGFkDeEtszuB5rZ8JYvCMcRmA+f6JnpaxoWyKGpUBk98K5EgweMz3Eir9WdBnhsZkwNHR0R0GKTwKw1T41mw2lRyT+V0KK4Mr/c7Ozj0GKdvlzWLwt6QIrRnAlX6yrBXaHES5wuBzpBBtGTC1Obx1BGEPxe4C871EitGSAUE3NKsQuguoaGhWIVQBVDU0qxDaFFDZ0KyCcgEmNleYnPE9/wIBNDSroNwFMPiyrKHB32/w5HOkEeUC4Alf4RKFjPixtMVisVgsFsvG8h97gIyKs5ldrgAAAABJRU5ErkJggg==') !important;
		background-size: 24px 24px !important;
		background-repeat: no-repeat !important;
		background-position: center !important;
		font-size: 0 !important;

		&:hover {
			background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGCSURBVHgB7ZrBdcIwEETHeSmAdOASKMHpIFSAOwgd2KkAOkhSASVQQtKB3UHoYLLGHLGkAwl4Nf+9fRysyw72jnYlQAghhBAiUwr8MSSX9rO48KgviqKHd0yAjpf5sVjDO5bkwuKL0zTIAUt0JxHINiDCfnhb4B1LchMQobMo4R1LcnkuglmLUDLsEEt4JyLCwAbe4WiT+4AIcghjixyIiDBsprKwyZpyiJNNdrmLELPJF3jnLIIaKaqRijrEO9VInT6VEt6xJCte0SaTZoIcK+49vWKlxdS331usbN74jQRSBSDmR20ifMYWeRbgaAI8xRY9wC/HlEWeBXhLWfSINFaYTxEc/vnn1CI4O65tg7PCkntlrhshS64JJP9Bz1thjnv9KVp4hfFzRb9TY+Y8EGF8JOb30MSSWzNjm2s4zYHOK/02kPwOXmH8aKyFVxif+tbwCuM2V8ErVEMziRoaeIWZNzSHQPK+r8EwXOlr3Jj/uCxdTTzK47K0EEIIIcS98guJ3hbC27IdgAAAAABJRU5ErkJggg==') !important;
		}

		&:after {
			content: none !important;
			display: none !important;
			opacity: 0 !important;
		}

		@media only screen and (max-width: 767px) {
			min-width: 40px !important;
			width: 40px !important;
			height: 40px !important;
		}
	}

	h4,
	.h4,
	h4 span {
		font-size: 38px !important;
		font-style: normal !important;
		font-weight: 700 !important;
		line-height: 120% !important;
		font-family: 'Roobert', sans-serif !important;
		color: #4d5257 !important;

		@media only screen and (max-width: 1919px) {
			font-size: 2.9232vw !important;
		}

		@media only screen and (max-width: 767px) {
			font-size: 20px !important;
		}
	}

	form > div {
		& > div:last-of-type {
			margin-bottom: 50px !important;
			display: flex;
			align-items: center;
			gap: 24px;
			flex-direction: row !important;

			& > div:first-of-type {
				flex: 1 !important;
			}
			& > div:last-of-type {
				flex: unset !important;
			}
		}
	}

	form:has(input[type='email']) {
		& > div {
			& > div:last-of-type {
				margin-top: 40px !important;
			}
		}
	}

	input + div {
		background-color: transparent !important;

		div {
			top: 0 !important;
			left: 0 !important;
			right: auto !important;
			background-color: transparent !important;

			div {
				border-radius: none !important;
				box-shadow: none !important;
				border: none !important;
				background-color: transparent !important;

				&:before,
				&:after {
					content: none !important;
				}

				span {
					font-family: 'Roobert', sans-serif !important;
					padding: 16px !important;
					color: #e64558 !important;
					font-size: 16px !important;
					font-style: normal !important;
					font-weight: 500 !important;
					line-height: 150% !important;

					@media only screen and (max-width: 767px) {
						font-size: 12px !important;
						padding: 0 !important;
						padding-top: 8px !important;
					}
				}
			}
		}
	}
}

.footerLegalText {
	color: #b3b7bc;

	@media only screen and (max-width: 1919px) {
		font-size: max(0.8352vw, 12px);
	}

	@media only screen and (max-width: 1023px) {
		margin-top: 40px;
	}

	@media only screen and (max-width: 767px) {
		font-size: 12px;
	}
}

.footerLegalTextLinks {
	color: #b3b7bc;

	@media only screen and (max-width: 1023px) {
		margin-top: 15px;
	}

	@media only screen and (max-width: 767px) {
		font-size: 12px;
	}
}

.socialMarginTop {
	@media only screen and (max-width: 500px) {
		margin-top: 24px;
	}
}

.socialMenu {
	display: flex;
	gap: 8px;

	a {
		width: 32px;

		svg {
			display: block;
			width: 32px;
		}
	}
}

.footerInnerWrap {
	display: flex;
	justify-content: space-between;
	gap: 20px;

	@media only screen and (max-width: 1023px) {
		flex-wrap: wrap;
		flex-direction: column-reverse;
		gap: 0;
	}
}

.footerLinksMainWrap {
	display: flex;
	gap: 80px;

	@media only screen and (max-width: 1919px) {
		gap: 4.176vw;
	}

	@media only screen and (max-width: 1023px) {
		justify-content: space-between;
		gap: 20px;
		flex-wrap: wrap;
	}

	@media only screen and (max-width: 767px) {
		gap: 0;

		.footer-menu-item-group.social {
			margin-top: 64px;
		}
	}
}