.confirm-modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: #000;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 1000;
}

.confirm-modal-success {
  display: block;
}

.confirm-modal-success-none {
  display: none;
}

.confirm-modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.confirm-modal-content {
  width: 500px;
  background-color: #fff;
  border-radius: 12px;
}

.confirm-modal-header {
  padding: 30px 30px 20px 30px;
  font-size: 28px;
  text-align: left;
  margin-bottom: -40px;
}

.confirm-modal-h2 {
  padding-bottom: 0.8rem;
}

.confirm-modal-body {
  padding: 5px 30px 20px 30px;
  text-align: left;
}

.confirm-modal-copy-body {
  font-size: 1.25rem;
  line-height: 1.7rem;
  padding-bottom: 1rem;
}

.confirm-modal-indent {
  margin-left: 1rem;
}

ol li.confirm-modal-li {
  margin-top: 12px;
  margin-bottom: 12px;
}

.confirm-modal-footer {
  padding: 0px 8px 20px 8px;
}

.close-confirm-modal {
  position: relative;
  left: 97%;
  top: -50px;
  width: 30px;
}

.background-green-confirm-modal {
  background-color: #82dc36;
  margin-bottom: 12px;
  line-height: 5px;
  width: 0px;
  margin-left: 30px;
  animation-name: stretch;
  animation-duration: 800ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 600ms;
}

.confirm-input-pass {
    width: 100%;
    margin-bottom: 1rem;
    padding: .5rem;
    font-size: 1rem;
}

.confirm-pass-error {
    color: red;
    margin-top: -.5rem;
    margin-bottom: 1rem;
}

.confirm-modal-error {
  color: red;
  margin-top: 1rem;
}

@keyframes stretch {
  from {
    width: 0px;
  }
  to {
    width: 100px;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .confirm-modal-content {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  .confirm-modal-footer {
    padding: 0px 30px 20px 30px;
  }
}
