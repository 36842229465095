.share-player-container {
  max-width: 1280px;
}

.share-player {
  width: 100%;
  display: block;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1216px;
  box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.2);
}

.share-image-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
}

.share-image {
  width: 23%;
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 32px;
  /* box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.2); */
  cursor: pointer;
}

.share-share-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 32px;
  padding-top: 10px;
  margin-left: 32px;
  margin-right: 10px;
}

h2.share-share-header {
  font-size: 2rem;
  font-weight: 500;
  color: #fff;
  text-align: left;
  margin-right: 32px;
  margin-bottom: 32px;
}

.share-share-social {
    margin-left: 0;
}

.share-share-icon-mobile {
  display: none;
}

.share-share-icon-link {
  color: #fff;
}

img.share-share-icon-img {
  border-radius: 0;
  margin-right: 14px;
  vertical-align: middle;
  width: 40px;
}

img.share-share-icon-img-modal {
  border-radius: 0;
  margin-left: 5px;
  margin-right: 5px;
  vertical-align: middle;
  width: 30px;
}

@media screen and (max-width: 768px) {
  .share-player {
    padding-left: 0;
    padding-right: 0;
  }

  .share-image-container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .share-image {
    width: 48%;
    margin-top: 0;
    display: block;
  }

  .share-image-none {
    display: none;
  }

  .share-share-container {
    margin-left: 5%;
    margin-right: 5%;
  }

  .share-share-social {
    display: flex;
}

  .share-share-icon-mobile {
    display: inline-block;
  }
}

@media screen and (max-width: 500px) {
  img.share-share-icon-img {
    width: 30px;
  }
}
