.all-games-bg {
    background-color: #fff;
}

.all-games-container {
    max-width: 1280px;
    margin: auto;
    padding: 50px 15px 50px 15px;
}

h1.all-games-header {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 32px;
}

h3.all-games-subheader {
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 32px;
}

.all-games-scroll-container {
    display: flex;
    overflow-x: auto;
}

.all-games-img {
    border: 0;
    border-radius: 12px;
    max-width: 301px;
    vertical-align: middle;
    display: inline-block;
    margin: 20px 15px 12px 0;
    cursor: pointer;
}

.all-games-img-last {
    margin-right: 0;
}

/* width */
.all-games-scroll-container::-webkit-scrollbar {
    height: 7px;
  }
  
  /* Track */
.all-games-scroll-container::-webkit-scrollbar-track {
    background: #fff; 
  }
   
  /* Handle */
.all-games-scroll-container::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgb(179, 179, 179);
  }
  
  /* Handle on hover */
.all-games-scroll-container::-webkit-scrollbar-thumb:hover {
    background: rgb(141, 141, 141); 
  }

@media screen and (max-width: 768px) {
    .all-games-img {
        margin-top: 30px;
    }
}

@media screen and (max-width: 400px) {
    h1.all-games-header {
        font-size: 2.5rem;
    }
}